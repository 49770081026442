import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import ls from "local-storage";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
 
// Demo styles, see 'Styles' section below for some notes on use.
//import 'react-accessible-accordion/dist/fancy-example.css';

function HomeIndex() {

    
  const [diceType, setDiceType] = useState('');
  const [diceNumber, setDiceNumber] = useState('');
  const [diceAdjuster, setDiceAdjuster] = useState("0");
  const [diceAlias, setDiceAlias] = useState("");  
  const [poolStatus] = useState("");
  const [diePool, setDiePool] = useState([]);
  const [poolCount, setPoolCount] = useState(1);
  //const [poolCount, setPoolCount] = useState(ls("poolCount") || 1);
  const [formattedText, setFormattedText] = useState([]);
  const [showRollButton, setShowRollButton] = useState(0);
  const [historyText, setHistoryText] = useState([]);
  const [reversedHistoryText, setReversedHistoryText] = useState([]);
  const [keyCount, setKeyCount] = useState(0);
  const [open, setOpen] = useState(false);


  //const [testArray, setTestArray] = useState([]);
  useEffect(() => {
    setDiePool(ls("diePoolArray") || []);
    setPoolCount(ls("poolCount") || 1);
  }, []);


  const addToPool = () => {
    ls("diePoolArray", [
        ...diePool,
        { 
        dp1 : poolCount,
        dp2 : diceType,
        dp3 : diceNumber,
        dp4 : diceAdjuster,
        dp5 : diceAlias,
        dp6 : poolStatus
        }
    ]);
    setDiePool(ls("diePoolArray"));
    var tempPoolCount = poolCount;
    tempPoolCount++;
    setPoolCount(tempPoolCount);
    ls("poolCount", tempPoolCount)
    clearState();
  };


  const handleSelectItem = (die) => {
    setDiePool(diePool.map(diePool => {
         if(diePool.dp1 !== die.dp1) return diePool
         if(diePool.dp6 === "checked"){
          setShowRollButton(showRollButton-1);
          return {...diePool, dp6: ""}
         }
         else {
          setShowRollButton(showRollButton+1);
          return {...diePool, dp6: "checked"}
         }         
    }))
  };


  const handleDiceTypeInput = e => {
    setDiceType(e.target.value);
  };

  const handleDiceNumberInput = e => {
    setDiceNumber(e.target.value);
  };

  const handleDiceAdjusterInput = e => {
    setDiceAdjuster(e.target.value);
  };  

  const handleDiceAliasInput = e => {
    setDiceAlias(e.target.value);
  };  

  const handleRemoveItem = die => {
    let newArray = diePool.filter(diePool => diePool.dp1 !== die.dp1);
    setDiePool(newArray);
    ls("diePoolArray", newArray);
  };

  const rollResult = () => {
    const dResult = Object.values(diePool);
    const min = 1;
    var randNum = 0;
    var tempRoll = 0;
    const tempComp = [];
    var keyCounter = keyCount;
    for(let y = 0; y < dResult.length; y++){
      if (dResult[y].dp6 === "checked"){
        for (let x = 0; x < parseInt(dResult[y].dp3, 10); x++){
          if (x === 0){
            keyCounter++;
            tempComp.push(<p key={keyCounter} style={{marginBottom: 0 + 'px'}}><b>{dResult[y].dp5}</b></p>);
          }
          tempRoll = min + (Math.round(Math.random() * (dResult[y].dp2 - min)));
          keyCounter++;
          tempComp.push(<p key={keyCounter} style={{marginBottom: 0 + 'px'}}>1D{dResult[y].dp2}={tempRoll}</p>);
          randNum += tempRoll;
        }
        if (dResult[y].dp4 > 0){
          keyCounter++;
          tempComp.push(<p key={keyCounter} style={{marginBottom: 0 + 'px'}}>Bonus=+{dResult[y].dp4}</p>);
        }
        else if (dResult[y].dp4 < 0){
          keyCounter++;
          tempComp.push(<p key={keyCounter} style={{marginBottom: 0 + 'px'}}>Penalty={dResult[y].dp4}</p>);
        }
        randNum = randNum + parseInt(dResult[y].dp4, 10);
      }
    }
    keyCounter++;
    var hours = (new Date().getHours() < 10? '0' : '') + new Date().getHours();
    var minutes = (new Date().getMinutes() < 10? '0' : '') + new Date().getMinutes();
    var seconds = (new Date().getSeconds() < 10? '0' : '') + new Date().getSeconds();

    tempComp.push(<p key={keyCounter} style={{marginBottom: 0 + 'px'}}><b>The total result is: {randNum}</b> <br/><span style={{fontStyle: "italic"}}>Timestamp: {hours}:{minutes}:{seconds}</span></p>);
    keyCounter++;
    tempComp.push(<hr key={keyCounter} style={{color: '#000000',backgroundColor: '#000000',height: .5,borderColor : '#000000'}}/>);
   
    setKeyCount(keyCounter);
    setFormattedText(tempComp);
    const nextHistory = [...historyText, tempComp]
    setHistoryText(nextHistory);
    setReversedHistoryText(nextHistory.slice().reverse())
    setShow(true);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const clearState = () => {
    setDiceType('');
    setDiceNumber('');
    setDiceAdjuster("0");
    setDiceAlias('');
  }

  const clearHistory = () => {
    setHistoryText([]);
    setReversedHistoryText([]);
  }

    return (
        <Layout>
            <Helmet>
                <title>www.DiceRoller.app</title>
                <meta name="description" content="DiceRoller Page" />
            </Helmet>

            <div id="main" className="alt">
                <section id="one">
                    <div className="inner">
                        <div className="grid-wrapper">
                            <div className="col-6 first-section">
                                <form method="post" action="#">
                                    <h3>Assign Dice</h3>
                                    <div className="col-12">
                                        <div className="select-wrapper mb-2 sel-3c sel-mr10">
                                            <label htmlFor="demo-category1">Type/<br/># of Sides</label>
                                            <select name="demo-category1" id="demo-category1" onChange={handleDiceTypeInput} value={diceType}>
                                                <option value=""></option>
                                                <option value="2">D2</option>
                                                <option value="4">D4</option>
                                                <option value="6">D6</option>
                                                <option value="8">D8</option>
                                                <option value="10">D10</option>
                                                <option value="12">D12</option>
                                                <option value="20">D20</option>
                                                <option value="30">D30</option>
                                                <option value="100">D100</option>
                                            </select>
                                        </div>
                                        <div className="select-wrapper mb-2 sel-3c sel-mr10">
                                            <label htmlFor="demo-category2">Quantity/<br/>of type</label>
                                            <select name="demo-category2" id="demo-category2" onChange={handleDiceNumberInput} value={diceNumber} >
                                                <option value=""></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                            </select>
                                        </div>
                                        <div className="select-wrapper mb-2 sel-3c">
                                            <label htmlFor="demo-category3">Bonus/<br/>Penalty</label>
                                            <select name="demo-category3" id="demo-category3" onChange={handleDiceAdjusterInput} value={diceAdjuster}>
                                                <option value="-10">-10</option>
                                                <option value="-9">-9</option>
                                                <option value="-8">-8</option>
                                                <option value="-7">-7</option>
                                                <option value="-6">-6</option>
                                                <option value="-5">-5</option>
                                                <option value="-4">-4</option>
                                                <option value="-3">-3</option>
                                                <option value="-2">-2</option>
                                                <option value="-1">-1</option>              
                                                <option value="0" selected>0</option>
                                                <option value="1">+1</option>
                                                <option value="2">+2</option>
                                                <option value="3">+3</option>
                                                <option value="4">+4</option>
                                                <option value="5">+5</option>
                                                <option value="5">+6</option>
                                                <option value="5">+7</option>
                                                <option value="5">+8</option>
                                                <option value="5">+9</option>
                                                <option value="5">+10</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div className="col-6">
                                            <div className="mb-5">
                                                <label htmlFor="demo-name">Description of Dice</label>
                                                <input type="text" name="demo-name" id="demo-name" defaultValue="" placeholder="Ex. Sword/Fireball/etc." autocomplete="no"  onChange={handleDiceAliasInput} value={diceAlias}/>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <ul className="actions small short-list">
                                                { diceType != '' && diceNumber > 0 && diceAlias != '' &&
                                                    <li><a href="#" className="button special small icon fa fa-plus" onClick={addToPool}>Add</a></li>
                                                }
                                                <li><a href="#" className="button small icon fa fa-undo" onClick={clearState}>Reset</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-6">
                                &nbsp;
                            </div>
                            <div className="col-6 second-section">
                                <h3>Dice Pool</h3>
                                <div className="table-wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Label</th>
                                                <th>Dice</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {diePool.map(die => (<tr key={die.dp1}><td> <input type="checkbox" id={die.dp1} onChange={() => handleSelectItem(die)}/><label htmlFor={die.dp1}>{die.dp5}</label></td><td>{die.dp3}D{die.dp2}+{die.dp4}</td><td><i class="icon fa fa-trash" dp1={die.dp1} onClick={() => handleRemoveItem(die)}></i></td></tr>))}
                                        </tbody>
                                    </table>
                                </div>
                                { showRollButton > 0 &&
                                    <div className="col-12">
                                        <ul className="actions small short-list">
                                            <li><a href="#openModal" className="button special small icon fa fa-dice" onClick={rollResult} >Roll Selected</a></li>
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className="col-6">
                                &nbsp;
                            </div>
                            <div className="col-6 third-section">
                                <Accordion allowZeroExpanded={true}>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Roll History
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                        {historyText.length > 0 &&
                                            <a href="#" className="button special small icon fa fa-dice" onClick={clearHistory} >Clear History</a>
                                            }
                                            {historyText.length === 0 &&
                                            "No History Available"
                                            }            
                                            
                                            {reversedHistoryText}
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>                        
                        </div>
                    </div>
                </section>
            </div>
            <div id="openModal" class="modalDialog">
            <div>
                    <a href="#close" title="Close" class="close icon fa fa-times-circle"></a>
                    <h3>Roll Result</h3>
                    {formattedText}																																		 
                </div>
            </div>
        </Layout>
    );
}
export default HomeIndex;